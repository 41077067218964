import { EditorSDK } from '@wix/platform-editor-sdk';

const TOKEN = '';

export async function setPermissionsPage(editorSDK: EditorSDK, appDefinitionId: string) {
  const { applicationId } = await editorSDK.document.tpa.app.getDataByAppDefId(TOKEN, appDefinitionId);
  const components = await editorSDK.document.tpa.app.getAllCompsByApplicationId(TOKEN, applicationId);
  if (components && components.length) {
    const component = components.filter((c) => c.type === 'TPA')[0];
    if (component) {
      const { pageId } = component;
      await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, () =>
        editorSDK.document.siteMembers.setCustomNoPermissionsPageId(TOKEN, { pageId }),
      );
    }
  }
}
