import { AppManifestBuilder } from '@wix/app-manifest-builder';
import { planWidgetSettingsEditor } from '@wix/bi-logger-membership/v2';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { ComponentRef } from '@wix/editor-platform-sdk-types/dist/types/common';
import {
  AppManifest,
  InitialAppData,
  WidgetInstallationType,
  EditorSDK,
  WidgetDesignPresets,
} from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import BenefitsComponent from './components/Benefits/.component.json';
import PlanComponent from './components/Plan/.component.json';
import SinglePlanWidget from './components/SinglePlanWidget/.component.json';
import { ElementRole } from './constants/elements';
import {
  DEFAULT_PRESETS_BY_STATE,
  isValidPresetId,
  MOBILE_PRESET_BY_DESKTOP_PRESET_ID,
  PRESETS,
  PRESETS_BY_STATE,
  RootPresetId,
  WidgetState,
} from './layout-config';
import { openShowHidePanel } from './showHidePanel/showHideActions';
import { SinglePlanInteractions } from './types/SinglePlanFedops';
import { toError } from './utils/errors';
import { assignLatestPlanToWidgetIfNeeded, getParentRef, getPlanWidget, getRootWidget } from './utils/widget';

const PLAN_COMPONENT_ID = 'xg6fd';
const HELP_ARTICLE_ID = '63f37cb0-aaff-4d10-868b-5da5a88db58a';

export enum GfppAction {
  OpenPlanSettings = 'openSettingsPanel',
  OpenElementsPanel = 'openShowHidePanel',
  OpenLayoutPanel = 'openLayoutPanel',
  OpenPlanForm = 'openPlanForm',
  OpenChangeRibbonTextPanel = 'openChangeRibbonTextPanel',
}

export const getBlocksWidgetManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options as InitialAppData & { appManifestBuilder: AppManifestBuilder };
  const baseManifest = await fetchWidgetsStageData(appData);
  const t = flowAPI.translations.t;

  const newManifest = appManifestBuilder
    .withJsonManifest(baseManifest)
    .configureWidget(SinglePlanWidget.controllerId, (builder) => {
      builder.set({ displayName: t('blocks.label.single-plan') });
      builder.gfpp().set('mainAction1', {
        actionId: GfppAction.OpenPlanSettings,
        label: t('blocks.gfpp.single-plan-settings'),
      });
      builder.gfpp().set('help', { id: HELP_ARTICLE_ID });
      builder.gfpp().set('design', { target: { role: ElementRole.PlanWidget } });
      builder.gfpp().set('add', { target: { role: ElementRole.PlanWidget } });
      builder.gfpp().set('connect', { behavior: 'HIDE' });
      builder.gfpp().set('layout', { target: { role: ElementRole.PlanWidget } });
      builder.configureConnectedComponents(ElementRole.PlanWidget, (planBuilder) => {
        planBuilder.behavior().set({ preventHide: true, closed: { hideFromHierarchy: true, selectable: false } });
      });
      builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
    })
    .configureWidget(PlanComponent.controllerId, (builder) => {
      builder.configureWidgetDesign((designBuilder) => {
        designBuilder.set({
          title: t('blocks.design.title'),
          presetsTitle: t('blocks.design.presets-title'),
          customHelpId: HELP_ARTICLE_ID,
        });
      });

      Object.values(WidgetState).forEach((state) => {
        builder.configureState(state, (stateBuilder) => {
          stateBuilder.configureWidgetDesign((designBuilder) => {
            const presets = getPresets({ appData, widgetId: PLAN_COMPONENT_ID, state });
            designBuilder.setPresets(presets);
          });
        });
      });

      builder.configureConnectedComponents(ElementRole.ImageContainer, (imageContainerBuilder) => {
        imageContainerBuilder
          .behavior()
          .set({ closed: { selectable: true, hideFromHierarchy: false }, preventHide: true });
        imageContainerBuilder.set({ displayName: t('blocks.label.image') });
        imageContainerBuilder
          .gfpp()
          .set('mainAction1', { actionId: GfppAction.OpenPlanForm, label: t('blocks.gfpp.change-image') });
        imageContainerBuilder.gfpp().set('settings', { behavior: 'HIDE' });
        imageContainerBuilder.gfpp().set('link', { behavior: 'HIDE' });
        imageContainerBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        imageContainerBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        imageContainerBuilder.configureWidgetDesign((imageDesignBuilder) => {
          imageDesignBuilder.set({
            title: t('blocks.image-design.title'),
            customHelpId: HELP_ARTICLE_ID,
          });
          const tabs = imageDesignBuilder.tabs();
          tabs.addTab((tabBuilder) => {
            tabBuilder
              .set({ label: t('blocks.image-design.tab-label') })
              .groups()
              .set({ roles: [ElementRole.Image, ElementRole.ImageContainer] });

            tabBuilder.addSection((sectionBuilder) => {
              sectionBuilder.set({
                state: 'regular',
                category: 'fill',
                label: '',
                priority: 1,
                roles: [ElementRole.Image],
                styleParam: 'mediaOpacity',
              });
            });
            tabBuilder.addSection((sectionBuilder) => {
              sectionBuilder.set({
                state: 'regular',
                category: 'corners',
                label: '',
                priority: 1,
                roles: [ElementRole.Image, ElementRole.ImageContainer],
              });
            });
          });
        });
      });

      builder.configureConnectedComponents(ElementRole.BadgeWidget, (ribbonBuilder) => {
        ribbonBuilder.set({ displayName: t('blocks.label.ribbon') });
        ribbonBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        ribbonBuilder.gfpp().set('mainAction1', {
          label: t('blocks.ribbon.edit-text'),
          actionId: GfppAction.OpenChangeRibbonTextPanel,
        });
        ribbonBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        ribbonBuilder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
        ribbonBuilder.configureWidgetDesign((ribbonDesignBuilder) => {
          ribbonDesignBuilder.set({ title: t('blocks.ribbon-design.title'), customHelpId: HELP_ARTICLE_ID });
          const tabs = ribbonDesignBuilder.tabs();

          tabs.addTab((tabBuilder) => {
            tabBuilder.set({ label: t('blocks.ribbon-design.text') });
            tabBuilder.groups().set({
              roles: [ElementRole.BadgeText],
            });
          });

          tabs.addTab((tabBuilder) => {
            tabBuilder.set({ label: t('blocks.ribbon-design.background') });
            tabBuilder.groups().set({
              roles: [ElementRole.BadgeContainer],
            });
          });
        });
      });

      builder.configureConnectedComponents(ElementRole.PlanName, (nameBuilder) => {
        nameBuilder.set({ displayName: t('blocks.label.plan-name') });
        nameBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
        nameBuilder.gfpp().set('mainAction1', {
          label: t('blocks.plan-name.gfpp.design-text'),
          onClick: (e) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            });
          },
        });
        nameBuilder.gfpp().set('mainAction2', {
          actionId: GfppAction.OpenPlanForm,
          label: t('blocks.plan-name.gfpp.edit-text'),
        });
        nameBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        nameBuilder.gfpp('mobile').set('mainAction1', {
          label: t('blocks.plan-name.gfpp.design-text'),
          onClick: (e) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            });
          },
        });
        nameBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        nameBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
      });

      builder.configureConnectedComponents(ElementRole.PricingWidget, (priceBuilder) => {
        priceBuilder.set({ displayName: t('blocks.label.pricing') });
        priceBuilder.gfpp().set('mainAction1', {
          actionId: GfppAction.OpenPlanForm,
          label: t('blocks.pricing.gfpp.edit-text'),
        });
        priceBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        priceBuilder.gfpp().set('mainAction2', { behavior: 'HIDE' });
        priceBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        priceBuilder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });

        priceBuilder.configureWidgetDesign((priceDesignBuilder) => {
          priceDesignBuilder.set({ title: t('blocks.pricing.design.title'), customHelpId: HELP_ARTICLE_ID });
          const tabs = priceDesignBuilder.tabs();

          tabs.addTab((tabBuilder) => {
            tabBuilder.set({ label: t('blocks.pricing.design.price'), dependents: [ElementRole.Price] });
            tabBuilder.groups().set({
              roles: [ElementRole.Price],
            });
          });

          tabs.addTab((tabBuilder) => {
            tabBuilder.set({ label: t('blocks.pricing.design.currency') });
            tabBuilder.groups().set({
              roles: [ElementRole.Currency],
            });
          });

          tabs.addTab((tabBuilder) => {
            tabBuilder.set({ label: t('blocks.pricing.design.frequency') });
            tabBuilder.groups().set({
              roles: [ElementRole.Frequency],
            });
          });
        });
      });

      builder.configureConnectedComponents(ElementRole.Description, (descriptionBuilder) => {
        descriptionBuilder.set({ displayName: t('blocks.label.plan-description') });
        descriptionBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
        descriptionBuilder.gfpp().set('mainAction1', {
          label: t('blocks.plan-description.gfpp.design-text'),
          onClick: (e) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            });
          },
        });
        descriptionBuilder.gfpp('mobile').set('mainAction1', {
          label: t('blocks.plan-description.gfpp.design-text'),
          onClick: (e) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            });
          },
        });
        descriptionBuilder.gfpp().set('mainAction2', {
          actionId: GfppAction.OpenPlanForm,
          label: t('blocks.plan-description.gfpp.edit-text'),
        });
        descriptionBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        descriptionBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        descriptionBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
      });

      builder.configureConnectedComponents(ElementRole.PlanDuration, (durationBuilder) => {
        durationBuilder.set({ displayName: t('blocks.label.plan-duration') });
        durationBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
        durationBuilder.gfpp().set('mainAction1', {
          label: t('blocks.plan-duration.gfpp.design-text'),
          onClick: (e) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            });
          },
        });
        durationBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        durationBuilder.gfpp('mobile').set('mainAction1', {
          label: t('blocks.plan-duration.gfpp.design-text'),
          onClick: (e) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            });
          },
        });
        durationBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        durationBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
      });

      builder.configureConnectedComponents(ElementRole.Button, (buttonBuilder) => {
        buttonBuilder
          .panel<'StylableButton', 'settings'>('settings')
          .configureControls({ label: { hidden: false }, link: { hidden: true }, icon: { hidden: false } });
        buttonBuilder.gfpp().set('mainAction1', {
          label: t('blocks.button.gfpp.change-text'),
          onClick: (e) =>
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: e.detail.componentRef,
              helpId: HELP_ARTICLE_ID,
            }),
        });
        buttonBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        buttonBuilder.gfpp().set('link', { behavior: 'HIDE' });
        buttonBuilder.gfpp().set('connect', { behavior: 'HIDE' });
      });

      builder.configureConnectedComponents(ElementRole.ContentDividerContainer, (dividerBuilder) => {
        dividerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
        dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
      });

      builder.configureConnectedComponents(ElementRole.ContentDividerVertical, (dividerBuilder) => {
        dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
        dividerBuilder.gfpp().set('mainAction1', {
          label: t('blocks.content-divider.gfpp.design'),
          onClick: (e) =>
            editorSDK.editor.openNativeComponentPanel('', 'design', { componentRef: e.detail.componentRef }),
        });
        dividerBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        dividerBuilder.gfpp().set('design', { behavior: 'HIDE' });
      });

      builder.configureConnectedComponents(ElementRole.ContentDividerHorizontal, (dividerBuilder) => {
        dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
        dividerBuilder.gfpp().set('mainAction1', {
          label: t('blocks.content-divider.gfpp.design'),
          onClick: (e) =>
            editorSDK.editor.openNativeComponentPanel('', 'design', { componentRef: e.detail.componentRef }),
        });
        dividerBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        dividerBuilder.gfpp().set('design', { behavior: 'HIDE' });
      });

      builder.configureConnectedComponents(ElementRole.PerksWidget, (perksBuilder) => {
        perksBuilder.set({ displayName: t('blocks.label.benefits') });
        perksBuilder
          .gfpp()
          .set('mainAction1', { label: t('blocks.benefits.gfpp.manage'), actionId: GfppAction.OpenPlanForm });
        perksBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        perksBuilder.gfpp().set('add', { behavior: 'HIDE' });
        perksBuilder.gfpp().set('connect', { behavior: 'HIDE' });
      });
    })
    .configureWidget(BenefitsComponent.controllerId, (builder) => {
      builder.gfpp().set('help', { id: HELP_ARTICLE_ID });
      builder.configureConnectedComponents(ElementRole.PerkText, (textBuilder) => {
        textBuilder.set({ displayName: t('blocks.label.benefit-text') });
        textBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
        textBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        textBuilder.gfpp().set('mainAction1', {
          label: t('blocks.benefits.text.design'),
          onClick: (e) =>
            editorSDK.editor.openNativeComponentPanel('', 'settings', { componentRef: e.detail.componentRef }),
        });
        textBuilder
          .gfpp()
          .set('mainAction2', { label: t('blocks.benefits.text.edit'), actionId: GfppAction.OpenPlanForm });
        textBuilder.gfpp().set('connect', { behavior: 'HIDE' });
      });
      builder.configureConnectedComponents(ElementRole.PerkIcon, (iconBuilder) => {
        iconBuilder.set({ displayName: t('blocks.label.benefit-icon') });
        iconBuilder.gfpp().set('help', { id: HELP_ARTICLE_ID });
        // TODO: Unhide when panel modularization is supported for icon element
        iconBuilder.gfpp().set('settings', { behavior: 'HIDE' });
        iconBuilder.gfpp().set('connect', { behavior: 'HIDE' });
        iconBuilder.gfpp().set('link', { behavior: 'HIDE' });
      });
    })
    .build();
  return newManifest;
};

function openSettingsPanel(params: { editorSDK: EditorSDK; componentRef: ComponentRef; flowAPI: EditorScriptFlowAPI }) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.settings-panel.title'),
      url: getPanelUrl('Plan', 'SettingsPanel'),
      height: 200,
      width: 300,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

function openLayoutPanel(params: { editorSDK: EditorSDK; componentRef: ComponentRef; flowAPI: EditorScriptFlowAPI }) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.layouts-panel.title'),
      url: getPanelUrl('Plan', 'LayoutPanel'),
      height: 296,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

export async function addSinglePlanWidget(editorSDK: EditorSDK, containerRef?: ComponentRef) {
  const defaultPlanPreset = DEFAULT_PRESETS_BY_STATE[WidgetState.Default];
  await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: SinglePlanWidget.id,
    installationType: WidgetInstallationType.Closed,
    containerRef,
    layout: {
      width: defaultPlanPreset.width,
      height: defaultPlanPreset.height,
      x: 0,
      y: 100,
    },
    scopedPresets: {
      desktop: {
        layout: RootPresetId.Desktop,
        style: RootPresetId.Desktop,
      },
    },
  });
}

function getPresets({
  appData,
  widgetId,
  state,
}: {
  appData: InitialAppData;
  widgetId: string;
  state: WidgetState;
}): WidgetDesignPresets {
  return PRESETS_BY_STATE[state].map(({ id, thumbnailWidth, thumbnailHeight, thumbnailSrc }) => ({
    id,
    layout: {
      width: thumbnailWidth,
      height: thumbnailHeight,
    },
    src: thumbnailSrc,
  }));
}

async function openPlanForm(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  const rootWidget = await getRootWidget(editorSDK, componentRef);
  const { planId } = await editorSDK.application.appStudioWidgets.props.get('', { widgetRef: rootWidget });
  const pricingPlansUrl = planId ? `pricing-plans/edit/${planId}` : 'pricing-plans/new';
  await editorSDK.editor.openDashboardPanel('', { url: pricingPlansUrl, closeOtherPanels: true });
  try {
    await assignLatestPlanToWidgetIfNeeded({
      widgetRef: rootWidget,
      httpClient: flowAPI.httpClient,
      editorSDK,
      currentPlanId: planId as string | undefined,
    });
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e));
  }
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'PLAN_FORM_CLOSED',
  });
}
async function collapsePerkDivider(editorSDK: EditorSDK, rootWidgetRef: ComponentRef) {
  const planComponent = await getPlanWidget(editorSDK, rootWidgetRef);
  const [perksWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planComponent,
    role: ElementRole.PerksWidget,
  });
  const [perkDivider] = await editorSDK.components.findAllByRole('', {
    controllerRef: perksWidget,
    role: ElementRole.PerkDivider,
  });
  return editorSDK.components.refComponents.collapseReferredComponent('token', {
    componentRef: perkDivider,
  });
}

async function openChangeRibbonTextPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.ribbon.change-text.title'),
      url: getPanelUrl('Ribbon', 'ChangeText'),
      height: 100,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

type GfppClickedPayload = { id: GfppAction; componentRef: ComponentRef };

export function onWidgetGfppClicked(params: {
  eventPayload: GfppClickedPayload;
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { eventPayload, editorSDK, flowAPI } = params;
  const { id, componentRef } = eventPayload;
  switch (id as GfppAction) {
    case GfppAction.OpenPlanSettings: {
      flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenSettingsPanel);
      openSettingsPanel({ editorSDK, componentRef, flowAPI });
      break;
    }
    case GfppAction.OpenElementsPanel: {
      openShowHidePanel(editorSDK, componentRef, flowAPI);
      break;
    }
    case GfppAction.OpenLayoutPanel: {
      flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenLayoutPanel);
      openLayoutPanel({ editorSDK, componentRef, flowAPI });
      break;
    }
  }
}

export function onComponentGfppClicked(params: {
  eventPayload: GfppClickedPayload;
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { eventPayload, editorSDK, flowAPI } = params;
  const { id, componentRef } = eventPayload;

  switch (id as GfppAction) {
    case GfppAction.OpenPlanForm:
      openPlanForm({ editorSDK, componentRef, flowAPI });
      break;
    case GfppAction.OpenChangeRibbonTextPanel:
      flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenRibbonTextPanel);
      openChangeRibbonTextPanel({ editorSDK, componentRef, flowAPI });
      break;
  }
}

export async function onGlobalDesignPresetChanged(params: {
  eventPayload: { preset: string; componentRef: ComponentRef };
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { eventPayload, editorSDK, flowAPI } = params;
  const { preset, componentRef } = eventPayload;
  if (isValidPresetId(preset)) {
    const rootWidget = await getRootWidget(editorSDK, componentRef);
    const rootWidgetParentRef = await getParentRef(editorSDK, rootWidget);
    const presetConfig = PRESETS[preset];
    const isFullWidth = await editorSDK.components.isFullWidth('', { componentRef: rootWidgetParentRef });
    if (!isFullWidth) {
      editorSDK.document.components.layout.update('', {
        componentRef: rootWidgetParentRef,
        layout: {
          width: presetConfig.width,
        },
      });
    }
    editorSDK.document.application.appStudioWidgets.changePreset('', {
      componentRef: await getParentRef(editorSDK, componentRef),
      layoutPresetId: MOBILE_PRESET_BY_DESKTOP_PRESET_ID[preset],
      stylePresetId: MOBILE_PRESET_BY_DESKTOP_PRESET_ID[preset],
      context: {
        viewport: 'MOBILE',
      },
    });
    biReportPresetChange({ editorSDK, rootWidget, flowAPI, presetId: preset });
  }
}
async function biReportPresetChange(params: {
  editorSDK: EditorSDK;
  rootWidget: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  presetId: string;
}) {
  const { editorSDK, rootWidget, flowAPI, presetId } = params;
  const props = await editorSDK.document.application.appStudioWidgets.props.get('', { widgetRef: rootWidget });
  flowAPI.bi?.report(
    planWidgetSettingsEditor({
      panelLabel: 'Single plan design',
      action: 'discover more designs',
      value: presetId,
      planGuid: props?.planId as string,
    }),
  );
}

export async function initNewSinglePlanWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  const planWidget = await getPlanWidget(editorSDK, componentRef);
  const defaultPlanPreset = DEFAULT_PRESETS_BY_STATE[WidgetState.Default];
  const planWidgetParentRef = await getParentRef(editorSDK, planWidget);

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef,
    layoutPresetId: RootPresetId.Mobile,
    stylePresetId: RootPresetId.Mobile,
    context: {
      viewport: 'MOBILE',
    },
  });

  editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'MOBILE',
    },
  });

  editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'DESKTOP',
    },
  });

  collapsePerkDivider(editorSDK, componentRef);

  try {
    await assignLatestPlanToWidgetIfNeeded({
      widgetRef: await getRootWidget(editorSDK, planWidget),
      httpClient: flowAPI.httpClient,
      editorSDK,
    });
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e));
  }
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'CONNECTED_COMPONENT_ADDED',
  });
}
